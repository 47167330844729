import React, { useState, useEffect, useMemo } from "react";
import "./App.css";

import { Alert, Grid } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";

import Menu from "./components/Menu";
import Viewer from "./components/Viewer";
import Calculator from "./components/Calculator";
import { Box } from "@mui/system";

import AppContext from "./AppContext";

import request, { requestForm, getRaw, getAWSRaw } from "./utils/request";

import ContactModal from "./components/ContactModal";
import EmailModal from "./components/EmailModal";

function App() {
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(null);
  const [client, setClient] = useState(null);
  const [quoterId, setQuoterId] = useState("");
  const [buildPlate, setBuildPlate] = useState([250, 250, 250]);
  const [color, setColor] = useState(0);

  const [firstKey, setFirstKey] = useState(false);

  const [creatingRequest, setCreatingRequest] = useState(false);
  const [uploadId, setUploadId] = useState(false);
  const [globalError, setGlobalError] = useState(false);

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(0);
  const [pdf, setPdf] = useState(false);
  const [pdfFinal, setPdfFinal] = useState(false);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [email, setEmail] = useState("");

  const formatCurrency = useMemo(() => {
    if (!client || !client.currency) {
      return new Intl.NumberFormat(undefined, {
        style: "currency",
        currency: "USD",
      });
    } else {
      return new Intl.NumberFormat(undefined, {
        style: "currency",
        currency: client.currency,
      });
    }
  }, [client]);

  const updateItems = (newItem) => {
    setItems((oldItems) => {
      let temp = [...oldItems];
      let i = temp.findIndex((element) => element._id == newItem._id);
      if (i === -1) return temp;
      temp[i] = { ...temp[i], ...newItem };
      return temp;
    });
  };

  const createRequest = async () => {
    try {
      const json = await request("/request", { quoterId: quoterId, email }, {});
      if (json.r == 1) {
        setUploadId(json.id);

        //window.location = window.location + "/" + json.id;
        let pathname = window.location.pathname.substring(1).split("/");
        if (pathname.length > 2) {
          pathname[2] = json.id;
        } else {
          pathname.push(json.id);
        }

        window.history.pushState({}, "", "/" + pathname.join("/"));
      } else {
        return setGlobalError(json.message);
      }
    } catch (error) {
      console.error("Error creating request:", error);
      setGlobalError("An error occurred while creating the request.");
    }
  };

  const initClient = async (id, requestId = false) => {
    const config = { method: "GET" };
    let reqId = "";
    if (requestId) {
      reqId = "/" + requestId;
    }
    const json = await request("/client/" + id + reqId, false, config);
    if (json.r == 1) {
      const client = json.client;
      client.materialConfigs = client.processes;
      if (client.type !== "basic" || client.enableExtendedConfiguration) {
        if (client.materialConfigs.length == 0) {
          return setGlobalError(
            "Calculator is not ready for use. No process found."
          );
        }

        if (client.materialConfigs[0].materialCategories.length == 0) {
          return setGlobalError(
            "Calculator is not ready for use. No material category found."
          );
        }

        if (
          client.materialConfigs[0].materialCategories[0].colors.length == 0
        ) {
          return setGlobalError(
            "Calculator is not ready for use. No color found."
          );
        }
      }

      let style = document.createElement("style");
      style.id = "editor-style";
      window.document.body.appendChild(style);

      window.addEventListener("message", (event) => {
        if (event.data.type == "editorChanged") {
          loadStyle(event.data.user);
        }
      });

      const loadStyle = (s) => {
        const newClient = {
          customStyle: s.customStyle,
          uploadButtonLabel: s.uploadButtonLabel,
          contactButtonLabel: s.contactButtonLabel,
          partBoxLabel: s.partBoxLabel,
          configurationBoxLabel: s.configurationBoxLabel,
          contactBoxLabel: s.contactBoxLabel,
          contactBoxText: s.contactBoxText,
        };

        if (s.customStyle) {
          if (s.primaryColor) {
            newClient.primaryColor = s.primaryColor;
          }
          if (s.secondaryColor) {
            newClient.secondaryColor = s.secondaryColor;
          }
          if (s.errorColor) {
            newClient.errorColor = s.errorColor;
          }
          if (s.warningColor) {
            newClient.warningColor = s.warningColor;
          }
          if (s.infoColor) {
            newClient.infoColor = s.infoColor;
          }
          if (s.successColor) {
            newClient.successColor = s.successColor;
          }
        }

        if (s.styleMode) {
          newClient.styleMode = s.styleMode;
        }

        setClient((oldClient) => {
          return { ...oldClient, ...newClient };
        });

        var innerStyle = "";
        if (!s.customStyle) {
          if (
            s.styleMode === "dark" ||
            (s.styleMode === "system" &&
              window.matchMedia &&
              window.matchMedia("(prefers-color-scheme: dark)").matches)
          ) {
            innerStyle += "body {background-color: #636363;}";
          }
          style.innerHTML = innerStyle;
          return;
        }

        if (s.bgcolor) {
          innerStyle += "body {background-color: " + s.bgcolor + ";}";
        } else if (
          s.styleMode === "dark" ||
          (s.styleMode === "system" &&
            window.matchMedia &&
            window.matchMedia("(prefers-color-scheme: dark)").matches)
        ) {
          innerStyle += "body {background-color: #636363;}";
        }
        var btnStyle = "";
        var btnHoverStyle = "";
        var boxStyle = "";
        if (s.buttonColor) {
          btnStyle += "background-color: " + s.buttonColor + ";";
        }
        if (s.buttonTextColor) {
          btnStyle += "color: " + s.buttonTextColor + ";";
        }

        if (s.buttonHoverColor) {
          btnHoverStyle += "background-color: " + s.buttonHoverColor + ";";
        }
        if (s.buttonTextHoverColor) {
          btnHoverStyle += "color: " + s.buttonTextHoverColor + ";";
        }

        if (s.borderColor) {
          innerStyle +=
            ".MuiDivider-root {border-color: " + s.borderColor + ";}";
        }

        if (s.boxColor) {
          boxStyle += "background-color: " + s.boxColor + ";";
        }

        if (s.textColor) {
          innerStyle += "body {color: " + s.textColor + ";}";
          innerStyle += ".MuiListItemIcon-root {color: " + s.textColor + ";}";
          innerStyle += ".MuiPaper-root label {color: " + s.textColor + ";}";
          boxStyle += "color: " + s.textColor + ";";
        }

        innerStyle += ".MuiButton-root {" + btnStyle + "}";
        innerStyle += ".MuiButton-root:hover {" + btnHoverStyle + "}";
        innerStyle += ".MuiPaper-root {" + boxStyle + "}";

        style.innerHTML = innerStyle;
      };

      if (json.client.customStyle) {
        loadStyle(json.client);
      } else {
        if (
          json.client.styleMode === "dark" ||
          (json.client.styleMode === "system" &&
            window.matchMedia &&
            window.matchMedia("(prefers-color-scheme: dark)").matches)
        ) {
          style.innerHTML += "body {background-color: #636363;}";
        } else {
          style.innerHTML = "";
        }
      }

      setClient(json.client);
      if (json.items.length > 0) {
        loadItems(json.items);
        if (json.email) {
          setEmail(json.email);
          setEmailSubmitted(true);
        }
        setStatus(json.status);
        setPdf(json.pdf);
        setPdfFinal(json.pdfFinal);
      }

      // temporary fix for first key
      setFirstKey(json.firstKey);
    }
  };

  const loadItem = async (item) => {
    try {
      let data = await getAWSRaw(encodeURIComponent(item.s3Key));

      const arrayBuffer = await data.arrayBuffer();
      const geometry = new STLLoader().parse(arrayBuffer);
      geometry.sourceType = "stl";
      geometry.sourceFile = item.filename;

      item.geometry = geometry;
    } catch (ex) {
      console.error(ex);

      setItems((oldItems) => {
        let temp = [...oldItems];
        const foundIndex = temp.findIndex((i) => i._id === item._id);
        if (foundIndex > -1) {
          temp[foundIndex] = {
            ...temp[foundIndex],
            error: true,
            message: "File is damaged or corrupted",
          };
        }

        return temp; //return oldItems.filter((i) => i._id !== item._id);
      });
    }

    setItems((oldItems) => {
      let temp = [...oldItems];
      const foundIndex = temp.findIndex((i) => i._id === item._id);
      if (foundIndex > -1) {
        temp[foundIndex] = {
          ...temp[foundIndex],
          loading: temp[foundIndex].loading - 1,
          geometry: item.geometry,
        };
      }
      return temp;
    });

    return item;
  };

  const loadItems = async (items) => {
    setItems(
      items.map((item, index) => {
        item.index = index;
        if (!item.loading) item.loading = 0;
        item.loading++;
        return item;
      })
    );
    setItem(items[0]);

    items.map((item, index) => {
      try {
        loadItem(item);

        return item;
      } catch (ex) {
        console.error(ex);
        item.error = true;
        item.message = "Error loading file";
        return item;
      }
    });
  };

  const deleteItem = async (item) => {
    if (item._id[0] === "-") {
      setItems((items) => {
        return items.filter((i) => i._id !== item._id);
      });
      return;
    }

    updateItems({ ...item, loading: item.loading + 1 });

    const json = await request(
      "/rfqPart/delete/" + item._id,
      { id: item.requestId },
      {}
    );
    if (json.r == 1) {
      setItems((items) => {
        return items.filter((i) => i._id !== item._id);
      });
    } else {
      updateItems({ ...item, loading: item.loading - 1 });
    }
  };

  useEffect(() => {
    const path = window.location.pathname.split("/");
    if (path.length > 3 && path[1] === "client" && path[3] !== "") {
      setUploadId(path[3]);
      setQuoterId(path[2]);
      initClient(path[2], path[3]);
    } else if (path.length > 2 && path[1] === "client") {
      setQuoterId(path[2]);
      initClient(path[2]);
    }

    document.title = "3D Quoter";
  }, []);

  useEffect(() => {
    if (!creatingRequest) return;
    createRequest();
  }, [creatingRequest]);

  useEffect(() => {
    if (!client) return;

    if (!item) return setItem(items[0]);

    const foundItem = items.find((i) => i._id === item._id);
    if (!foundItem) return setItem(items[0]);

    setItem(foundItem);
  }, [items]);

  const context = useMemo(() => {
    return {
      client,
      items,
      item,
      uploadId,
      quoterId,
      email,
      buildPlate,
      color,
      status,
      pdf,
      pdfFinal,
      createRequest,
      setClient,
      setItems,
      updateItems,
      setItem,
      deleteItem,
      setEmail,
      setBuildPlate,
      setColor,
      setStatus,
      setPdf,
      setCreatingRequest,
      formatCurrency,
    };
  }, [
    client,
    items,
    item,
    uploadId,
    quoterId,
    email,
    buildPlate,
    color,
    status,
    formatCurrency,
  ]);

  const theme = useMemo(() => {
    const style = {};

    if (client) {
      style.palette = {};

      if (client.customStyle) {
        if (client.primaryColor) {
          style.palette.primary = { main: client.primaryColor };
        }
        if (client.secondaryColor) {
          style.palette.secondary = { main: client.secondaryColor };
        }
        if (client.errorColor) {
          style.palette.error = { main: client.errorColor };
        }
        if (client.warningColor) {
          style.palette.warning = { main: client.warningColor };
        }
        if (client.infoColor) {
          style.palette.info = { main: client.infoColor };
        }
        if (client.successColor) {
          style.palette.success = { main: client.successColor };
        }
      }

      if (client.styleMode) {
        if (client.styleMode === "dark") {
          style.palette.mode = "dark";
        } else if (client.styleMode === "system") {
          if (
            window.matchMedia &&
            window.matchMedia("(prefers-color-scheme: dark)").matches
          ) {
            style.palette.mode = "dark";
          }
        }
      }
    }

    return createTheme(style);
  }, [client]);

  if (globalError) {
    return (
      <Box
        sx={{
          width: "400px",
          maxWidth: "100%",
          height: "540px",
          maxHeight: "99%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        id="3d-calculator"
      >
        <Alert severity="error" sx={{ marginTop: "100px" }}>
          <strong>ERROR!</strong>
          <div>{globalError}</div>
        </Alert>
      </Box>
    );
  }

  if (!client) return null;

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={context}>
        <Box
          sx={{
            width: "1100px",
            maxWidth: "100%",
            height: "540px",
            maxHeight: "99%",
            display: "flex",
          }}
          id="3d-calculator"
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={items.length === 0 ? 12 : 3}
              sx={{ height: "100%" }}
            >
              <Menu firstKey={firstKey}></Menu>
            </Grid>
            <Grid
              sx={{ height: "100%", display: items.length === 0 ? "none" : "" }}
              item
              xs={12}
              md={6}
            >
              <Viewer></Viewer>
            </Grid>
            <Grid
              sx={{ height: "100%", display: items.length === 0 ? "none" : "" }}
              item
              xs={12}
              md={3}
            >
              <Calculator openContactModal={setOpen}></Calculator>
            </Grid>
          </Grid>
          <ContactModal open={open} setOpen={setOpen} />
        </Box>

        {client.requireEmail && (
          <EmailModal
            client={client}
            open={emailSubmitted}
            setOpen={setEmailSubmitted}
            setEmail={setEmail}
            email={email}
          />
        )}
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
